@import '@angular/cdk/overlay-prebuilt.css';
@import 'flatpickr/dist/flatpickr.css';
@import 'normalize.css';
@import 'styles/grid';
@import 'styles/variables';
@import 'styles/mixins';
@import 'styles/forms';

*,
*::before,
*::after {
  box-sizing: border-box;
}

html,
body {
  padding: 0;
  margin: 0;
  min-height: 100dvh;
  overscroll-behavior: none;
}

body {
  font-family: 'Open Sans', sans-serif;
  color: $text-grey;

  font-size: 18px;
  font-weight: 300;
  line-height: 30px;
}

img {
  max-width: 100%;
}

small {
  line-height: 1em;
}

.small {
  display: block;
  font-size: 13px;
  line-height: 16px;
}

.clickable,
[ng-reflect-router-link] {
  cursor: pointer;
}

.caps {
  text-transform: uppercase;
}

.no-select-text {
  user-select: none;
}

.no-wrap {
  white-space: nowrap;
}

.content-vertical-padding {
  padding: 170px 0 90px;

  @include breakpoint(mario) {
    padding: 130px 0 90px;
  }

  @include breakpoint(luigi) {
    padding: 90px 0;
  }

  @include breakpoint(peach) {
    padding: 70px 0;
  }
}

// simple grid

$gutter-size: 20px;

.container {
  @extend %container;
}

.minor-section-heading {
  display: block;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  text-transform: uppercase;
}

.big-title {
  text-align: center;

  small {
    display: block;
    font-size: 24px;
    line-height: 24px;
    font-weight: 600;

    margin-bottom: 30px;

    @include breakpoint(luigi) {
      margin-bottom: 20px;
    }

    @include breakpoint(toad) {
      margin-bottom: 10px;
    }
  }

  // prefer h2 here, but h1 is left to make sure we don't break any old code
  h1,
  h2 {
    font-size: 72px;
    line-height: 72px;
    font-weight: 800;
    text-transform: uppercase;
    margin: 0 0 60px;

    @include breakpoint(mario) {
      font-size: 62px;
      line-height: 62px;
    }

    @include breakpoint(luigi) {
      font-size: 54px;
      line-height: 54px;
      margin: 0 0 40px;
    }

    @include breakpoint(peach) {
      font-size: 42px;
      line-height: 42px;
    }

    @include breakpoint(toad) {
      font-size: 36px;
      line-height: 36px;
    }
  }

  strong {
    font-weight: 600;
  }

  p {
    max-width: 440px;
    margin: -30px auto 60px;

    @include breakpoint(luigi) {
      margin-bottom: 20px;
    }

    &.regular-margins {
      margin-top: 0;
    }

    &.blerg {
      margin-bottom: 20px;
    }
  }
}

.narrow {
  max-width: 440px;
  margin: 0 auto;
  width: 100%;
}

.member-narrow {
  max-width: 550px;
  margin: 0 auto;
  width: 100%;

  padding-bottom: 150px;
}

.extra-margin {
  margin-bottom: 60px;
}

.center {
  text-align: center;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

a,
.link {
  cursor: pointer;
  color: $red;
  text-decoration: none;

  &:hover,
  &:active {
    color: lighten($red, 6%);
  }
}

.btn {
  display: inline-flex;
  align-items: center;
  justify-content: center;

  outline: 0 none;
  border: transparent 0.166em solid;
  background: transparent;
  padding: 1em 1.5em;
  min-width: 200px;
  text-align: center;
  font-size: 18px;
  color: white;
  font-weight: 800;
  text-transform: uppercase;
  line-height: 1em;
  cursor: pointer;
  white-space: nowrap;
  text-decoration: none;

  transition: 0.2s ease-in-out;
  transition-property: background-color, border-color, color;

  &.btn-with-icon {
    gap: 0.5rem;
  }

  @include breakpoint(peach) {
    padding: 0.666em 1em;
  }

  &:disabled,
  &.disabled {
    cursor: not-allowed;

    &:not(.btn-text) {
      background: $light-medium-grey;
      border-color: $light-medium-grey;
    }
  }
}

.btn-primary,
.btn-danger,
.btn-error {
  @include button($red);
}

.btn-success,
.btn-success {
  @include button($success-green);
}

.btn-alt,
.btn-info {
  @include button($blue);
}

.btn-warn {
  @include button($warn-yellow);
}

.btn-outline {
  background: transparent !important;

  &:hover,
  &:active {
    background: transparent !important;
  }

  &:disabled,
  &.disabled {
    border-color: $light-medium-grey !important;
    color: $light-medium-grey !important;
  }
}

.btn-text {
  min-width: 0;
  border-color: transparent;
  background: transparent !important;
  color: $light-medium-grey;

  &:hover,
  &:active,
  &:focus {
    border-color: transparent;
    background: transparent !important;
  }

  &:disabled,
  &.disabled {
    border-color: transparent !important;
    color: $light-medium-grey !important;
  }

  &.btn-text-inline {
    padding: 0;
    border: 0;
  }
}

.btn-huge {
  font-size: 24px;
  line-height: 24px;
  padding: 38px 50px;

  // back to normal once we get too small
  @include breakpoint(luigi) {
    padding: 21px 30px;
    font-size: 18px;
    line-height: 18px;
  }
}

.btn-small {
  padding: 12px 18px;
}

.btn-collapse {
  min-width: 0;
}

.btn-block {
  display: block;
  width: 100%;
}

.form-submit-btn {
  margin-top: 56px;
}

h2 {
  font-weight: 400;
  line-height: 48px;
  font-size: 36px;
}

h3 {
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
  margin: 0 0 0.5em;
}

.larger-body {
  font-weight: 300;
  font-size: 24px;
  line-height: 48px;

  @include breakpoint(luigi) {
    font-size: 18px;
    line-height: 32px;
  }
}

p {
  margin: 0 0 1em;
}

h2,
h3,
h4,
h5,
h6 {
  margin-top: 0;
}

.dragging {
  position: relative;

  &::after {
    content: 'Drop File to Add It as a Pending Upload';

    border: 4px dashed rgba($medium-grey, 0.5);

    display: flex;
    align-items: center;
    justify-content: center;

    font-weight: 800;

    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;

    background: rgba($light-medium-grey, 0.75);
  }
}

.control-grid {
  display: grid;
  grid-template-columns: 20px 1fr auto;
  grid-column-gap: 12px;
  grid-template-areas:
    'icon title content'
    'icon text content';

  align-items: start;

  padding-bottom: 10px;
  padding-top: 10px;

  border-bottom: 1px solid $lightest-grey;

  .control-grid-icon {
    grid-area: icon;
    color: $light-medium-grey;
  }

  .control-grid-title {
    grid-area: title;

    font-weight: 600;
    text-transform: uppercase;
  }

  .control-grid-text {
    grid-area: text;
    font-weight: 300;
    font-size: 14px;
    line-height: 18px;
  }

  .control-grid-content {
    grid-area: content;
    justify-self: end;
    align-self: center;
    text-align: right;
  }
}

// shrug
.double-hero-row {
  @include breakpoint(toad) {
    padding: 0 15px;
  }

  ems-hero {
    margin-top: 20px;

    @include breakpoint(toad) {
      margin-top: 10px;
    }
  }

  ::ng-deep .col-toad-6 {
    @include breakpoint(350px) {
      min-width: 100% !important;
    }
  }
}

.search-controls {
  display: flex;
  align-items: center;
  margin: 0 0 30px;
}

.member-area-header {
  border-bottom: 1px solid $light-medium-grey;

  padding-bottom: 20px;
  margin-bottom: 20px;

  @include breakpoint(peach) {
    padding-bottom: 15px;
    margin-bottom: 15px;
  }

  h1 {
    margin: 0;
    font-weight: 300;

    @include breakpoint(peach) {
      font-size: 22px;
      line-height: 30px;
    }

    &.smaller {
      font-size: 22px;
      line-height: 30px;

      @include breakpoint(peach) {
        font-size: 18px;
        line-height: 26px;
      }
    }
  }

  .btn {
    @include breakpoint(peach) {
      min-width: 0;
    }
  }

  &.sticky {
    padding-top: 20px;
    margin-top: -20px;
    position: sticky;
    top: $member-header-height;
    background: white;
    z-index: 1;

    @include breakpoint(peach) {
      padding-top: 15px;
      margin-top: -15px;
    }
  }
}

.flex-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex {
  display: flex;
  align-items: center;
}

table {
  width: 100%;

  table-layout: fixed;

  th {
    text-align: left;
  }
}

.round-icon {
  border: none;
  padding: 0;
  border-radius: 50px;
  background-color: $red;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;

  width: 2.15em;
  height: 2.15em;
  font-size: 0.85em;

  &.disabled {
    background: $light-grey;
    cursor: not-allowed;
  }

  &.fetching-file {
    fa-icon {
      @keyframes spinner {
        from {
          transform: rotateY(0deg);
        }
        to {
          transform: rotateY(-360deg);
        }
      }

      perspective: 130px;
      animation-name: spinner;
      animation-timing-function: linear;
      animation-iteration-count: infinite;
      animation-duration: 1.5s;

      transform-style: preserve-3d;
    }
  }
}

a.round-icon {
  &:hover {
    color: white;
  }
}

.search-input-wrapper {
  display: flex;

  .btn {
    width: 50px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 26px;
  }
}

// global CDK styles

.cdk-overlay-backdrop {
  background-color: rgba(#3b3d45, 0.6);
}

.cdk-overlay-pane {
  > ng-component {
    display: block;
    width: 100%;
    height: 100%;
  }
}

[cdkDragHandle] {
  cursor: grab !important;

  &:active {
    cursor: grabbing !important;
  }
}

.cdk-drag-preview {
  box-sizing: border-box;
  border-radius: 4px;
  box-shadow:
    0 5px 5px -3px rgba(0, 0, 0, 0.2),
    0 8px 10px 1px rgba(0, 0, 0, 0.14),
    0 3px 14px 2px rgba(0, 0, 0, 0.12);
}

.cdk-drag-placeholder {
  opacity: 0;
}

.cdk-drag-animating {
  transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
}

.cdk-drop-list-dragging {
  pointer-events: none;

  [cdkDrag]:not(.cdk-drag-placeholder) {
    transition: transform 250ms cubic-bezier(0, 0, 0.2, 1);
  }
}

.ccc-course-icon {
  color: #222 !important;
}
.ems-course-icon {
  color: $blue !important;
}
.fire-course-icon {
  color: $red !important;
}
.nursing-course-icon {
  color: $yellow !important;
}

.toggle-buttons {
  display: flex;

  .btn {
    flex-grow: 1;
  }

  .not-selected {
    @include button($light-medium-grey);
  }
}

// Breakpoint Helper (for dev only)
// --------------------------------
.breakpoint-helper {
  display: none;
  // display: block;
  position: fixed;
  right: 0;
  bottom: 0;
  padding: 5px 10px;
  border-radius: 4px 0 0 0;
  z-index: 1001;
  font-size: 24px;

  @include breakpoint(super-mario) {
    background: yellow;
    color: black;

    span::before {
      content: 'Super Mario';
    }
  }
  @include breakpoint(mario) {
    background: red;
    color: white;

    span::before {
      content: 'Mario';
    }
  }
  @include breakpoint(luigi) {
    background: green;
    color: white;

    span::before {
      content: 'Luigi';
    }
  }
  @include breakpoint(peach) {
    background: pink;
    color: black;

    span::before {
      content: 'Peach';
    }
  }
  @include breakpoint(toad) {
    background: white;
    color: black;

    span::before {
      content: 'Toad';
    }
  }
}

// hide stuff for print mode
.only-print {
  display: none;
}

@media print {
  .no-print {
    display: none !important;
  }

  .only-print {
    display: block !important;
  }

  .main-menu {
    display: none !important;
  }

  .member-header {
    display: none !important;
  }
}
